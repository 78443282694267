import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { theme } from "../common/theme";
import { ResetCSS } from "../assets/css/style";
import { ContentWrapper, GlobalStyle } from "../containers/saas.style";
import Navbar from "../containers/common/Navbar";
import Footer from "../containers/common/Footer";
import { DrawerProvider } from "../contexts/DrawerContext";
import SEO from "../components/SEO";
import { graphql } from "gatsby";
import BlogPostSection from "../containers/blog/BlogPostSection";

const postTemplate = ({ data, pageContext }) => {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <SEO
          title={`${pageContext.title}`}
          url={pageContext.slug}
          image={pageContext.featuredImage.src}
          type="article"
          keywords={pageContext.tags}
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar notMainPage />
            </DrawerProvider>
          </Sticky>
          <BlogPostSection post={pageContext} html={data.markdownRemark.html} />
          <Footer notMainPage />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
    }
  }
`;

postTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
};

export default postTemplate;
